import { __awaiter, __generator } from "tslib";
import { noop } from 'lodash';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { playbackCorrelationChain } from 'shared/bi/correlation/playback';
var MOBILE_OVERLAY_ENTRY = 'mobile-overlay.html';
var FULL_SCREEN_OVERLAY_ENTRY = 'full-screen-modals.html';
export function createOpenOverlayHandlers(_a) {
    var _this = this;
    var wixCodeApi = _a.wixCodeApi, compId = _a.compId, appParams = _a.appParams;
    var staticsBaseUrl = appParams.baseUrls.staticsBaseUrl;
    if (!staticsBaseUrl.endsWith('/')) {
        staticsBaseUrl = staticsBaseUrl + '/';
    }
    var getRootCompId = function () { return "rootCompId=" + compId; };
    var getRootPageId = function () { return "rootPageId=" + wixCodeApi.site.currentPage.id; };
    var getLastCorrelationId = function () {
        return "correlationId=" + playbackCorrelationChain.getLastCorrelationId();
    };
    var _b = wixCodeApi.window, scrollTo = _b.scrollTo, viewMode = _b.viewMode;
    function openPopup(path, width, height, theme, onClose) {
        if (onClose === void 0) { onClose = noop; }
        return __awaiter(this, void 0, void 0, function () {
            var scroll, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, wixCodeApi.window.getBoundingRect()];
                    case 1:
                        scroll = (_a.sent()).scroll;
                        return [4 /*yield*/, wixCodeApi.window.openPopup(path, {
                                compId: compId,
                                width: width,
                                height: height,
                                position: {
                                    origin: 'FIXED',
                                    placement: 'CENTER',
                                },
                                theme: theme,
                            }, compId)];
                    case 2:
                        result = _a.sent();
                        onClose(result && result.message && result.message.reason);
                        scrollTo(scroll.x, scroll.y);
                        return [2 /*return*/];
                }
            });
        });
    }
    var openOverlay = function (entry) { return function (subPath, onClose) {
        if (subPath === void 0) { subPath = ''; }
        if (onClose === void 0) { onClose = noop; }
        return __awaiter(_this, void 0, void 0, function () {
            var modalPath;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalPath = "" + staticsBaseUrl + entry + "?" + getRootCompId() + "&" + getRootPageId() + "&" + getLastCorrelationId() + subPath;
                        return [4 /*yield*/, openPopup(modalPath, '100%', '100%', 'BARE', onClose)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }; };
    return {
        openMobileOverlay: function (subPath, onClose) {
            if (viewMode === VIEW_MODES.PREVIEW) {
                scrollTo(0, 0);
            }
            openOverlay(MOBILE_OVERLAY_ENTRY)(subPath, onClose);
        },
        openFullScreenOverlay: openOverlay(FULL_SCREEN_OVERLAY_ENTRY),
    };
}
