import { __assign } from "tslib";
import _ from 'lodash';
/* global ENV_CONFIG */
import { BI_SOURCES } from '@wix/wix-vod-constants/dist/bi/sources';
import events from 'shared/bi/events';
import { playbackCorrelationChain } from 'shared/bi/correlation/playback';
import { logBI } from '@wix/wix-vod-shared/dist/src/common/logger';
export function createVodBiLogger(factory, defaultParams) {
    if (defaultParams === void 0) { defaultParams = {}; }
    var knownEvents = _.mapValues(events, function (evid) { return ({ evid: evid }); });
    var biFactory = factory({ endpoint: 'video' })
        .updateDefaults(__assign({ src: BI_SOURCES.VOD_SRC_ID }, defaultParams))
        .setEvents(knownEvents);
    if (ENV_CONFIG.disableBi) {
        biFactory._publishers.splice(0, 1);
    }
    if (ENV_CONFIG.logBiSend) {
        biFactory.addPublisher(function (event) {
            var eventName = _.findKey(knownEvents, { evid: event.evid });
            logBI({ eventName: eventName, event: event });
        });
    }
    var logger = biFactory.logger({ endpoint: 'video' });
    return withCorrelation(logger);
}
var withCorrelation = function (biLoggerInstance) {
    var originalLog = biLoggerInstance.log.bind(biLoggerInstance);
    biLoggerInstance.log = function (eventName, params) {
        var correlationId = playbackCorrelationChain.getCorrelationId(eventName);
        originalLog(eventName, __assign(__assign({}, params), (correlationId && { correlationId: correlationId })));
    };
    return biLoggerInstance;
};
